/* =============================
    General
=============================*/

:root {
    --main-color: #23376D;
    --secundary-color: #152142;
    --header-color: #263A4B;
}

[class*="primary"]:not(.uk-text-primary):not(.uk-link-primary) {
    background-color: var(--secundary-color);
}

.uk-section-muted {
    background-color: rgba(35, 55, 109, .04);
}

.uk-text-primary,
.uk-text-primary>*,
.uk-link-primary {
    color: var(--header-color) !important;
}

p,
.uk-navbar-nav>li>a {
    color: var(--header-color);
}

.uk-light {
    color: #ffffff;
}

/*=============================
    Home
=============================*/

.shadow-header {
    text-shadow: 2px 2px 6px rgba(0, 0, 0, .5);
}

span.countdown-separator {
    display: inline-block;
    height: 70px;
    width: 3px;
    line-height: 70px;
    border-radius: 5px;
    margin: 0 15px;
}

.uk-countdown-number {
    font-weight: 300;
}

code {
    background-color: #272822 !important;
    color: #f8f8f2 !important;
    border-radius: 0.3rem !important;
    padding: 4px 5px 5px !important;
    white-space: nowrap !important;
    font-size: 3.25rem !important
  }

.counter-home .uk-countdown-number {
    color: #ffffff;
}

.counter-home span.countdown-separator {
    background-color: #ffffff;
}

.counter-thanks .uk-countdown-number {
    color: var(--header-color);
}

.counter-thanks span.countdown-separator {
    background-color: var(--header-color);
}

@media (min-width: 640px) {

    .uk-countdown-number,
    .shadow-header {
        font-size: 4rem;
    }
}

#info>div>p {
    font-weight: 100;
}

.spacing,
#info>div>p {
    letter-spacing: .2rem;
}

/*=============================
    Forms
=============================*/

.uk-input,
.uk-select,
.uk-textarea,
.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
    color: var(--main-color);
    border-color: var(--main-color);
}

.uk-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--main-color);
}

.uk-input::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--main-color);
}

.uk-input:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--main-color);
}

.uk-input:-moz-placeholder {
    /* Firefox 18- */
    color: var(--main-color);
}

.uk-checkbox:checked,
.uk-checkbox:indeterminate,
.uk-radio:checked,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus,
.uk-radio:checked:focus {
    background-color: var(--main-color);
}

.uk-checkbox:focus,
.uk-radio:focus {
    border-color: var(--main-color);
}

.uk-button[disable] {
    opacity: .2;
    pointer-events: none;
}

.selection>div>label {
    position: relative;
    display: block;
    height: 100%;
}

.selection>div>label input {
    display: block;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--main-color);
    padding: 24px 50px;
}

.selection>div>label span {
    position: absolute;
    width: 90%;
    left: 40%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--main-color);
}

.selection>div>label span:hover {
    cursor: pointer;
}

.selection>div>label:hover input,
.selection>div>label input:checked {
    background-color: var(--main-color);
    background-image: none;
}

.selection>div>label:hover input+span,
.selection>div>label input:checked+span {
    color: #ffffff;
}

@media screen and (min-width: 960px) {
    .yes-no {
        width: 50%;
        margin: 0 auto;
    }
}

.labelKey {
    position:relative; 
    left: 22px; 
    top: 8px
}

.table-wrapper {
    background: #fff;
    padding: 20px 25px;
    margin: 30px 0;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.table-reports {
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.table-add-reports{
    margin-left: 28px !important;
}
.table-title {        
    padding-bottom: 15px;
    /* background: #435d7d; */
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
}
.table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
}
.table-title .btn-group {
    float: right;
}
.table-title .btn {
    color: #fff;
    float: right;
    font-size: 13px;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
}
.table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
}
.table-title .btn span {
    float: left;
    margin-top: 2px;
}
table.table tr th, table.table tr td {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
}
table.table tr th:first-child {
    width: 60px;
}
table.table tr th:last-child {
    width: 100px;
}
table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
}	
table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
}
table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
    outline: none !important;
}
table.table td a:hover {
    color: #2196F3;
}
table.table td a.edit {
    color: #FFC107;
}
table.table td a.delete {
    color: #F44336;
}
table.table td i {
    font-size: 19px;
}
table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
}


.paginationReports {
    margin: 0px 65px 5px !important;
    display: flex !important;
    padding-left: 0 !important;
    list-style: none !important;
    border-radius: 0.25rem !important
}

.paginationReports li a {
    border: none;
    font-size: 13px;
    min-width: 30px;
    min-height: 30px;
    color:#4a5568;
    margin: 0 9px;
    line-height: 30px;
    border-radius: 2px !important;
    text-align: center;
    padding: 0 6px;
}
.paginationReports li a:hover {
    color: #666;
}	
.paginationReports li.active a, .pagination li.active a.page-link {
    background: #03A9F4;
}
.paginationReports li.active a:hover {        
    background: #0397d6;
}
.paginationReports li.disabled i {
    color: #ccc;
}
.paginationReports li i {
    font-size: 16px;
    padding-top: 6px
}


.pagination {
    float: right;
    margin: 0 0 5px;
}

.MuiDialog-paperWidthSm{
    max-width: none !important;
    min-width: 1000px !important;
    width: 100% !important;
    height: 100% !important;
}

.pagination li a {
    border: none;
    font-size: 13px;
    min-width: 30px;
    min-height: 30px;
    color:#4a5568;
    margin: 0 2px;
    line-height: 30px;
    border-radius: 2px !important;
    text-align: center;
    padding: 0 6px;
}
.pagination li a:hover {
    color: #666;
}	
.pagination li.active a, .pagination li.active a.page-link {
    background: #03A9F4;
}
.pagination li.active a:hover {        
    background: #0397d6;
}
.pagination li.disabled i {
    color: #ccc;
}
.pagination li i {
    font-size: 16px;
    padding-top: 6px
}
.hint-text {
    float: left;
    margin-top: 10px;
    font-size: 13px;
}    

.hint-text-forms-reports {
    float: left;
    margin-top: 10px;
    margin: 0 59px;
    white-space: nowrap;
    font-size: 13px;
}  
/* Custom checkbox */
.custom-checkbox {
    position: relative;
}
.custom-checkbox input[type="checkbox"] {    
    opacity: 0;
    position: absolute;
    margin: 5px 0 0 3px;
    z-index: 9;
}
.custom-checkbox label:before{
    width: 18px;
    height: 18px;
}
.custom-checkbox label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    background: white;
    border: 1px solid #bbb;
    border-radius: 2px;
    box-sizing: border-box;
    z-index: 2;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    transform: inherit;
    z-index: 3;
    transform: rotateZ(45deg);
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
    border-color: #03A9F4;
    background: #03A9F4;
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
    border-color: #fff;
}
.custom-checkbox input[type="checkbox"]:disabled + label:before {
    color: #b8b8b8;
    cursor: auto;
    box-shadow: none;
    background: #ddd;
}
/* Modal styles */
.modal .modal-dialog {
    max-width: 400px;
}
.modal .modal-header, .modal .modal-body, .modal .modal-footer {
    padding: 20px 30px;
}
.modal .modal-content {
    border-radius: 3px;
}
.modal .modal-footer {
    background: #ecf0f1;
    border-radius: 0 0 3px 3px;
}
.modal .modal-title {
    display: inline-block;
}
.modal .form-control {
    border-radius: 2px;
    box-shadow: none;
    border-color: #dddddd;
}
.modal textarea.form-control {
    resize: vertical;
}
.modal .btn {
    border-radius: 2px;
    min-width: 100px;
}	
.modal form label {
    font-weight: normal;
}

.success {
    background-color:#28a745;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}

.containerTable {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 230px;
    margin-left: auto;
}

.badgeSurvey {
    margin-left: 51% !important;
    background: #28a745 !important;
}

.pointer {
    cursor: pointer;
    vertical-align: middle !important;
}