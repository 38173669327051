.uk {
    &-modal-dialog {
        border-radius: 30px;
        //transform: translateY(12%) !important;
        background: $white url(../../images/burbles.png) no-repeat right top;
        top:8%;
    }

    &-modal-header {
        border-radius: 30px 30px 0 0;
    }

    &-modal-footer {
        border-radius: 0 0 30px 30px;
        border-top: none;
        color: $gray-1;
    }

    &-modal-title {
        color: $color-1 !important;
        text-transform: capitalize;
        font-weight: 100;
        font-size: 50px;
    }

    &-modal-close-default {
        color: $color-1;
        right: 26px;
        font-size: 40px;
    }

    &-modal-body {
        font-size: 18px;
        color: $gray-1;

        .logo {
            top: -100px !important;
            left: -115px !important;

        }

        // @include MQ(MD){
        //     padding: 0;
        // }
    }

    &-input {

        background: transparent;
        border: none;
        border-bottom: 1px solid #707070;
    }
}