@import 'variables/vars';
@import 'config/breakpoints';
@import 'config/base';
@import 'components/buttons';
@import 'components/forms';
@import 'components/modals';
@import 'layout/header';
@import 'layout/dashboard/body';
@import 'layout/dashboard/questions';

@import 'layout/admin/header';


.mobile-menu a{

  font-size:12px !important;
  text-shadow: none !important;
}

.MuiIconButton-root.Mui-disabled{
  color: none !important;
  background-color: transparent !important
}

.btn-add-report{
  border-radius: 10px;
  font-size: 12pt;
  box-shadow: 4px 7px 0 rgba(0, 0, 0, .1);
  color: #fff;
  // background-color: #1e87f0 !important;
  padding: 0 15px;
  margin: auto;
  letter-spacing: 0em;
  text-transform: none;
  white-space: nowrap;
}

.btn-add-report:hover{
  background-color: #1e87f0 !important;
}

.MuiButton-textPrimary{
  color: #fff !important;
  background-color: #152142 !important;
}
.MuiButton-textPrimary:hover{
background-color: #1e87f0 !important;
}

.icon-a-add-report{
  background-color: rgb(30, 135, 240);
  height: 30px;
  color: #fff !important;
}

.icon-a-add-report:hover {
  background-color: #152142 !important;
}

// .search-table-outter { overflow-x: scroll; }
// ul, li { min-width: 200px; }

.grid-list-scroll-x{
  display: grid;
  grid-template-rows: repeat(5, 30px);
  grid-auto-flow: column;
  grid-auto-columns: 235px;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 10px;
  padding-bottom: 20px;
}

/* ===== Scrollbar ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $scrollbar-color transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent!important;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color !important;
  border-radius: 9px;
  border: 5px solid transparent !important;
}

.MuiDialog-root{
  position: fixed;
  z-index: 0 !important; 
  inset: 0px;
}


