.dashboard{
    
    @include MQ(SM){
    
        p{
            font-size: 16px;
        }
    }

    h2{
        color: $color-1 !important;
        // text-transform: capitalize;
        //font-family: "Muli", sans-serif;
        font-weight: normal;
    }

    .uk-icon-link{
        color:#fff;
    }


    .logo{
        top:15px;
        left:15px;

        @include MQ(SM){
            top:0;
            left:0;
            transform: scale(0.8);
           
        }
    
    }

}

.question-cta{
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    color:#fff;
    text-decoration: none;

    &:hover{
        text-decoration: none;
        color:$white;

    }

    

    h3{
        color:$gray-1 !important;
        font-family: 'Muli', sans-serif; 
        // text-transform: capitalize;
        margin:0;   
    }
    
    .bottom-info{
       
        
        b{display: block;}
        font-size: 16px;
        .uk-padding-large{
            padding: 20px 70px;

            @include MQ(SM){
                flex-direction:column;
                padding: 15px 35px;
                text-align: center;

                span{
                    margin-bottom: 10px;
                }
            }
        }
    }

    &.available{
        background: lighten($color-6, 40) url(../../../images/icon-exclamation.svg) no-repeat 95% 10% / 18px;
        .bottom-info{
            background: $color-6;
        }

        @include MQ(SM){
            background: lighten($color-6, 40) none;
        }
    }

    &.complete{
        background: lighten($color-4, 40)  url(../../../images/icon-check.svg) no-repeat 95% 20% / 90px;
        .bottom-info{
            background: $color-4;
        }

         @include MQ(SM){
            background: lighten($color-4, 40) none;
        }
    }


    &.disable{
        background: lighten($gray-1, 50)  url(../../../images/icon-check--disable.svg) no-repeat 95% 20% / 90px;
        .bottom-info{
            background: $gray-1;
        }

         @include MQ(SM){
            background: lighten($color-1, 50) none;
        }
    }
}


